*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.page-content{
  padding: calc(20px) 0px 60px 0px;
  width: 95vw;
  display: grid;
  place-content: center;
}
.headline {
  z-index: 100;
 
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
